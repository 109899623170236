/* Dashboard.module.css */

.container {
    padding-left: 5px;
    margin-top: -20px;
    font-family: Arial, sans-serif;
    /* background-color: #f9f5f1; */
}

.greeting {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;

}

.cards {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.card {
    position: relative;
    width: 150px;
    background-color: #d29556;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    color: white;
}

.cardImage {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.cardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardIcon {
    font-size: 24px;
}

.cardCount {
    font-size: 18px;
    font-weight: bold;
}

.cardLabel {
    padding: 10px;
    font-size: 16px;
}

.expiredSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.subTitle {
    font-size: 18px;
    font-weight: bold;
}

.viewAll {
    color: #d29556;
    font-weight: bold;
    cursor: pointer;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    font-weight: 600;
}

.highlightRow {
    background-color: #f7e3e3;
}

.editIcon,
.deleteIcon {
    cursor: pointer;
    font-size: 16px;
}

.enableButton {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.disableButton {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}