.formContainer {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.heading {
    font-weight: 700;
    margin-bottom: 20px;
}

.formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 45%;
    min-width: 280px;
}

/* @media (min-width: 600px) {
    .formGroup {
        display: flex;
        flex-direction: column;
      
      align-items: start !important;
    }
  } */

.label {
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
    min-width: 280px;
}

.inputField,
.selectField {
    border: none;
    border-bottom: 1px dotted #333;
    padding: 8px 0;
    font-size: 16px;
    background: none;
    outline: none;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.cancelButton {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.saveButton {
    background-color: #5cb85c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.cancelButton:hover {
    background-color: #c9302c;
}

.saveButton:hover {
    background-color: #4cae4c;
}